/* eslint-disable */
const {
	md5
} = require('../utils/md5.js')
export default {
	//首先这是我在utilsjs文件里做参数签名需要的方法
	timest(times) { //通过时间戳生成当前时间精确到秒，这是必要参数
		let date = new Date(times) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let Y = date.getFullYear()
		let M =
			(date.getMonth() + 1 < 10 ?
				'0' + (date.getMonth() + 1) :
				date.getMonth() + 1)
		let D =
			(date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
		let h =
			(date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
		let m =
			(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
		let s =
			date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
		return Y + M + D + h + m + s //返回的数据形式是这种202105281420
	},

	sortObj(obj) { //需要给我们的参数按ascll码(字典序)做升序排列
		var keysArr = Object.keys(obj).sort();
		var sortObj = {};
		// for (var i in keysArr) {sortObj[keysArr[i]] = encodeURIComponent(obj[keysArr[i]]); }
		for (var i in keysArr) {
			sortObj[keysArr[i]] = obj[keysArr[i]];
		}
		return sortObj;
	},

	generateSignText(params, key) { //排序后通过&符拼接
		let isthrim = true;
		for (var k in params) {
			if (params[k] == '' || params[k] == null) {
				isthrim = false
				break;
			}
		}
		let arr = [];
		for (var i in params) {
			arr.push((i + "=" + params[i]));
		}
		let newUrl = arr.join(("&")) + '&key=' + key; //这里的key是后端给的，固定值要拼到最后
		return newUrl
	},

	getsign(params, key) { //获取签名这也是最关键的一步
		let nonce1 = Math.random().toString(36).substr(2); //获取随机字符串
		let nonce2 = Math.random().toString(36).substr(2); //因为这个方法是我网上搜的，字符串长度不够
		let nonce = nonce1.concat("-").concat(nonce2) //所以这里我做了一下拼接
		let times = this.timest(Date.now()) //获取当前时间
		let timesTwo = Date.parse(new Date()) / 1000
		if (params == undefined || params == null) { //这里的params也是接口需要穿的参数
			params = {}; //如果是没有，那代表是get请求，就赋值为空对象
		}
		let obj = this.sortObj(params); //按升序排列
		let signText = this.generateSignText(obj, key); //获取拼接的文本
		let sign = md5(signText) //通过md5加密，MD5方法小程序需要引入一个js文件
		let headers = { // 拿到签名后把数据格式整理一下
			'x-nonce': nonce,
			'x-tmsp': times,
			'xx-tmsp': timesTwo,
			'x-sign': sign.toUpperCase()
		}
		return headers //因为这些参数我们都要加到请求头里去的
	},
	// 英文方法，隔开
	formatNumber(num) {
		let strNum = num.toString(); // 将数字转换为字符串类型
		let arrNum = strNum.split("."); // 分离整数和小数部分
		let intPart = arrNum[0]; // 整数部分
		let decimalPart = arrNum.length > 1 ? "." + arrNum[1] : ""; // 小数部分（如果有）
		let regExp = /(\d)(?=(\d{3})+$)/g; // 正则表达式，匹配每三位数字

		intPart = intPart.replace(regExp, "$1,"); // 将整数部分按照正则表达式替换成“逗号+数字”的形式

		// 去掉小数部分末尾的多余的 0
		if (decimalPart !== "") {
			decimalPart = decimalPart.replace(/0+$/, "");
			if (decimalPart === ".") {
				decimalPart = "";
			}
		}

		return intPart + decimalPart;
	}


}