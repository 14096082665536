import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import qs from 'qs'
Vue.prototype.$qs = qs
Vue.config.productionTip = false

// // 引入并启用 vConsole 库
// const script = document.createElement('script')
// script.type = 'text/javascript'
// script.async = true
// script.src = 'https://cdn.bootcss.com/vConsole/3.9.5/vconsole.min.js'
// script.onload = function () {
//   const vConsole = new window.VConsole();
//   vConsole.setSwitchPosition(80, 80)
// }
// document.body.appendChild(script)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
