import axios from "axios";
const http = axios.create({
  //  baseURL: 'https://life-api-test.bawanli.com',//测试地址
 baseURL: 'https://life-api-uat.bawanli.com',//测试地址
// baseURL: 'https://app.bawanli.com',//正式地址
  timeout: 90000 
})
let loadingInstance;

//拦截器  -请求拦截
http.interceptors.request.use(
  config => {
    config.headers['country-site'] = 'MM'
    config.headers['Authorization'] ='Bearer '+localStorage.getItem('zhichongToken')
  return config;
}, err => {
  return Promise.reject(err)
}
)

//拦截器  -响应拦截
http.interceptors.response.use(
  response => {
    const res = response.data
    loadingInstance && loadingInstance.close();
    if (res.code !== 200) {
      
      return Promise.resolve(res)
    } else {
    return Promise.resolve(res)
  }
},err=>{
  return Promise.reject(err)
});

  
export default http;
