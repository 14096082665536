<template>
  <div class="myHome">
    <div class="zf" v-if="abox == false">
      <div class="back" @click="backorder()">返回</div>
      <div id="loc" ref="qrcode" class="rwm"></div>
      <div class="wphtitle" v-if="letc === '1'">微信支付</div>
      <div class="Yz" v-if="letc === '1'">
        ¥{{ Number(wxdata.total_fee).toFixed(2) }}
      </div>
      <div class="jydh" v-if="letc === '1'">
        交易单号：{{ wxdata.out_trade_no }}
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from 'qrcodejs2'
import { settoken } from '@/utils/auth'
import { placeAnOrder, checkPay, appcheckPay } from '@/API/user'
import utils from '@/utils/utils.js'

export default {
  name: 'myHome',
  data () {
    return {
      wxdata: {},
      letc: '',
      order_no: '',
      query: {}
    }
  },
  created () {
    console.log('进入当前页面', location)
    const params = {}
    // 替换&amp;为&
    if (location.href.indexOf('&amp%3B') != -1) {
      const decodedUrl = location.href.replace(/&amp%3B/g, '&')
      const urlObj = new URL(decodedUrl)

      urlObj.searchParams.forEach((value, key) => {
        if (key !== 'kbz_url') {
          params[key] = value
        }
      })
    } else {
      if (location.href.indexOf('source') != -1) {
        // 匹配kbz_url参数值
        const kbzUrlMatch = location.href.match(/kbz_url=([^&]+)/)
        if (kbzUrlMatch) {
          // 获取kbz_url值
          const kbzUrl = kbzUrlMatch[1]

          // 添加到参数对象
          params['kbz_url'] = kbzUrl
        }

        // 解析其他参数使用URL对象
        const urlObj = new URL(location.href)
        urlObj.searchParams.forEach((value, key) => {
          if (key !== 'kbz_url') {
            params[key] = value
          }
        })
      }

      console.log('tag', params, encodeURI(this.$route.query.kbz_url))
    }

    if (this.$route.query.source == 'app') {
      console.log(params)
      this.order_no = params.order_sn
      localStorage.setItem('query', JSON.stringify(params))
      let key = '9bwht7zb5rmhv5ygmbicbz8vbmhnvp'
      top.location.href = encodeURI(this.$route.query.kbz_url)
      let headers = utils.getsign(
        {
          order_no: this.order_no
        },
        key
      )
      this.timer = setInterval(async () => {
        let data = await appcheckPay({
          order_no: this.order_no,
          sign: headers['x-sign']
        })
        if (data.error === 0) {
          console.log(data, this.$route.query)
          if (params.merchant_url.indexOf('?order_id=') != -1) {
            top.location.href = encodeURI(
              params.merchant_url + params?.order_no
                ? '&order_no=' + params.order_no
                : ''
            )
          } else {
            top.location.href = encodeURI(params.merchant_url)
          }
        }
      }, 4000)
    } else if (this.$route.query.source == 'zhichong') {
      this.order_no = params.order_sn
      localStorage.setItem('ZCquery', JSON.stringify(params))
      // let key = '9bwht7zb5rmhv5ygmbicbz8vbmhnvp'
      top.location.href = encodeURI(this.$route.query.kbz_url)
    } else {
      if (this.$route.query.a) {
        let a = JSON.parse(this.$route.query.a)
        console.log(a, '找找isWx')
        console.log(a.isWx, '找找a.isWx')
        if (a.isWx) {
          // 调用微信的jsapi支付
          let data = a.data
          // // 检测支付环境中的 WeixinJSBridge
          setTimeout(() => {
            if (typeof WeixinJSBridge == 'undefined') {
              if (document.addEventListener) {
                console.log('来了1')
                document.addEventListener(
                  'WeixinJSBridgeReady',
                  this.onBridgeReady(data),
                  false
                )
              } else if (document.attachEvent) {
                console.log('来了2')
                document.attachEvent(
                  'WeixinJSBridgeReady',
                  this.onBridgeReady(data)
                )
                document.attachEvent(
                  'onWeixinJSBridgeReady',
                  this.onBridgeReady(data)
                )
              }
            } else {
              this.onBridgeReady(data)
            }
          }, 500)
        } else {
          this.creadersize()
          this.creaderdata()
        }
        console.log(this.$route.query.a, '执行')
      } else {
        console.log(localStorage.getItem('query'))
        if (localStorage.getItem('query')) {
          console.log('1')
          this.query = JSON.parse(localStorage.getItem('query'))
          this.order_no = this.query.order_sn
          localStorage.removeItem('query')
          let key = '9bwht7zb5rmhv5ygmbicbz8vbmhnvp'
          let headers = utils.getsign(
            {
              order_no: this.order_no
            },
            key
          )
          this.timer = setInterval(async () => {
            let data = await appcheckPay({
              order_no: this.order_no,
              sign: headers['x-sign']
            })
            if (data.error === 0) {
              console.log(data, this.$route.query)
              top.location.href = encodeURI(
                this.query.merchant_url + this.query?.order_no
                  ? this.query.order_no
                  : ''
              )
            }
          }, 4000)
        } else {
          console.log('2')
          let url =
            localStorage.getItem('returnAddress') +
            'usermy/myOorder?storeId=' +
            localStorage.getItem('storeID')
          location.href = encodeURI(url)
        }
      }
    }
  },
  mounted () {
    this.domain = window.location.hostname + '/submitAfterapp'
    document.addEventListener('visibilitychange', this.is_Visible)
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.is_Visible)
  },
  methods: {
    onBridgeReady (data) {
      console.log('进来了11111', data)
      let params = {
        debug: true,
        appId: data.appId, //公众号ID，由商户传入
        timeStamp: data.timeStamp.toString(), //时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, //随机串
        package: data.package,
        signType: data.signType, //微信签名方式：
        paySign: data.paySign //微信签名
      }
      // console.log(,'什么底薪',WeixinJSBridge.log);
      WeixinJSBridge.invoke('getBrandWCPayRequest', params, function (res) {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          console.log('pay success')
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        } else {
          console.log(res.err_msg)
        }
      })
    },
    is_Visible: function (e) {
      if (!this.$route.query.source) {
        if (this.$route.query.a) {
          this.creadersize()
          let a = JSON.parse(this.$route.query.a)
          console.log('执行这里吗？', e.target.visibilityState)
          if (this.abox === true) {
            if (e.target.visibilityState === 'visible') {
              console.log(a.type === '1')
              if (a.type === '1') {
                let b = {
                  type: '1',
                  orderNo: a.orderNo,
                  payeeSettingId: a.payeeSettingId,
                  userId: a.userId,
                  storeId: a.storeId
                }
                let url = a.returnAddress + 'usermy/myOorder?storeId=' + a.storeId+'&b='+JSON.stringify(b)
                location.href = encodeURI(url)
              }else if (a.type === 2) {
                let b = {
                  type: '0',
                  orderNo: a.orderNo,
                  payeeSettingId: a.payeeSettingId,
                  userId: a.userId,
                  storeId: a.storeId
                }
                let url = a.returnAddress + 'usermy/myOorder?storeId=' + a.storeId+'&b='+JSON.stringify(b)
                location.href = encodeURI(url)
              }else if (a.type === '6') {
                let b = {
                  type: '4',
                  orderNo: a.orderNo,
                  payeeSettingId: a.payeeSettingId,
                  userId: a.userId,
                  storeId: a.storeId
                }
                let url = a.returnAddress + 'usermy/myOorder?storeId=' + a.storeId+'&b='+JSON.stringify(b)
                location.href = encodeURI(url)
              }
            }
          }
        } else {
          if (localStorage.getItem('query')) {
            this.query = JSON.parse(localStorage.getItem('query'))
            this.order_no = this.query.order_sn
            localStorage.removeItem('query')
            let key = '9bwht7zb5rmhv5ygmbicbz8vbmhnvp'
            let headers = utils.getsign(
              {
                order_no: this.order_no
              },
              key
            )
            this.timer = setInterval(async () => {
              let data = await appcheckPay({
                order_no: this.order_no,
                sign: headers['x-sign']
              })
              if (data.error === 0) {
                console.log(data, this.$route.query)
                top.location.href = encodeURI(
                  this.query.merchant_url + this.query?.order_no
                    ? this.query.order_no
                    : ''
                )
              }
            }, 4000)
          } else {
            console.log('3')
            let url =
              localStorage.getItem('returnAddress') +
              'usermy/myOorder?storeId=' +
              a.storeId
            location.href = encodeURI(url)
          }
        }
      }
    },
    backorder () {
      this.$router.go(-1)
    },
    // 监听屏幕宽度
    creadersize () {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let a = false
      if (w > 767) {
        a = false
      } else {
        a = true
      }
      this.abox = a
    },
    //  间隔两面查询订单支付状态
    async inquireState () {
      let a = JSON.parse(this.$route.query.a)
      if (a.type === '1') {
        if (this.abox === false) {
          let b = {
            type: '1',
            orderNo: a.orderNo,
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          this.timer = setInterval(async () => {
            let data = await checkPay(b)
            if (data.code === 200) {
              let url = a.returnAddress + 'Successhtml?value=true'+'&b='+JSON.stringify(b)+'&storeId='+a.storeId
              location.href = encodeURI(url)
            }
          }, 4000)
        } else {
          let b = {
            type: '1',
            orderNo: a.orderNo,
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          this.timer = setInterval(async () => {
            let data = await checkPay(b)
            if (data.code === 200) {
              // console.log(data)
              let url = a.returnAddress + 'Successhtml?value=true'+'&b='+JSON.stringify(b)+'&storeId='+a.storeId
              console.log(data, url)
              location.href = encodeURI(url)
            }
          }, 4000)
        }
      } else if (a.type === 2) {
        let b = {
          type: '0',
          orderNo: a.orderNo,
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        this.timer = setInterval(async () => {
          let data = await checkPay(b)
          if (data.code === 200) {
            let url = a.returnAddress + 'Successhtml?value=true'+'&b='+JSON.stringify(b)+'&storeId='+a.storeId
            location.href = encodeURI(url)
          }
        }, 1000)
      } else if (a.type === '6') {
        let b = {
          type: '4',
          orderNo: a.orderNo,
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        this.timer = setInterval(async () => {
          let data = await checkPay(b)
          if (data.code === 200) {
            let url = a.returnAddress + 'Successhtml?value=true'+'&b='+JSON.stringify(b)+'&storeId='+a.storeId
            console.log(data, url)
            location.href = encodeURI(url)
          }
        }, 4000)
      }
    },
    getQRcode (data) {
      this.creadersize()
      if (this.abox === false) {
        new Qrcode(this.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: data,
          width: 200,
          height: 200,
          colorDark: '#333', //二维码颜色
          colorLight: '#fff', //二维码背景颜色
          correctLevel: Qrcode.CorrectLevel.L //容错率,L/M/H
        })
      } else {
        new Qrcode(this.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: data,
          width: 300,
          height: 300,
          colorDark: '#333', //二维码颜色
          colorLight: '#fff', //二维码背景颜色
          correctLevel: Qrcode.CorrectLevel.L //容错率,L/M/H
        })
      }
    },
    // 初始化获取订单打开支付
    async creaderdata () {
      let a = JSON.parse(this.$route.query.a)
      localStorage.setItem('returnAddress', a.returnAddress)
      localStorage.setItem('storeID', a.storeId)
      settoken(a.token)
      this.letc = a.type
      this.creadersize()
      console.log('支付接口调用')
      this.inquireState()
      if (this.abox === true) {
        if (a.payMethod === 1) {
          let data1 = {
            orderNo: a.orderNo,
            type: '4',
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          // 在当前URL后面添加一个简单的参数，以区别于正常情况下的页面路由地址
          // history.pushState(null, '', '?from=wx');
          let { data } = await placeAnOrder(data1)
          console.log(data, '接口调用成功')
          if (data) {
            this.wxdata = data
            location.href = data.h5_url
          }
        } else if (a.payMethod === 4) {
          let data1 = {
            orderNo: a.orderNo,
            type: '6',
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: localStorage.getItem('storeID')
          }
          let { data } = await placeAnOrder(data1)
          if (data) {
            location.href = data.qr_url || data.qr_code
          }
          console.log(data.qr_url || data.qr_code)
          // location.href = data.qr_code
          console.log(data)
        }
      } else {
        let data1 = {
          orderNo: a.orderNo,
          type: '2',
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        let { data } = await placeAnOrder(data1)
        this.getQRcode(data.code_url)
        this.wxdata = data
      }
    }
  }
  // beforeDestroy() {
  // 	clearInterval(this.timer);
  // },
}
</script>

<style>
.myHome {
  width: 100%;
  min-height: 910px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.zf {
  width: 800px;
  height: 800px;
  border: 1px solid #c7c6c6;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.back {
  cursor: pointer;
  position: absolute;
  width: 65px;
  height: 30px;
  border: 1px solid #6e4faa;
  border-radius: 15px;
  line-height: 30px;
  font-family: 'Microsoft YaHei';
  text-align: center;
  color: #6e4faa;
  font-weight: 600;
  left: 10px;
  top: 10px;
}

.wphtitle {
  margin-top: 30px;
}

.Yz {
  margin-top: 20px;
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: normal;
  color: #ff4545;
}

.jydh {
  margin-top: 20px;
  color: rgb(189, 189, 189);
}
</style>
