import Cookies from 'js-cookie'

const TokenKey= 'Admin_token'

export function gettoken() {
  return Cookies.get(TokenKey)
}
export function settoken(value) {
  return Cookies.set(TokenKey,value)
}
export function removetoken() {
  return Cookies.remove(TokenKey)
}
