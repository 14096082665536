<template>
  <div class="myHome">
    <div class="zf" v-if="abox == false">
      <div class="back" @click="backorder()">返回</div>
      <div id="loc" ref="qrcode" class="rwm"></div>
      <div class="wphtitle" v-if="letc === '2'">微信支付</div>
      <div class="Yz" v-if="letc === '2'">
        ¥{{ Number(wxdata.total_fee).toFixed(2) }}
      </div>
      <div class="jydh" v-if="letc === '2'">
        交易单号：{{ wxdata.out_trade_no }}
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from 'qrcodejs2'
import { settoken } from '@/utils/auth'
import { placeAnOrder, checkPay, appcheckPay } from '@/API/user'
import utils from '@/utils/utils.js'
export default {
  name: 'myHome',
  data () {
    return {
      wxdata: {},
      letc: ''
    }
  },
  created () {
    console.log('进入当前页面2')

    if (this.$route.query.a) {
      this.creadersize()
      this.creaderdata()
    } else {
      if (localStorage.getItem('query')) {
        console.log('1')
        this.query = JSON.parse(localStorage.getItem('query'))
        this.order_no = this.query.order_sn
        
        let key = '9bwht7zb5rmhv5ygmbicbz8vbmhnvp'
        let headers = utils.getsign({ order_no: this.order_no }, key)
        this.timer = setInterval(async () => {
          let data = await appcheckPay({
            order_no: this.order_no,
            sign: headers['x-sign']
          })
          console.log(data, this.query, '这里这里')
          if (data.error === 0) {
            console.log(data, this.$route.query)
            let b = this.query.merchant_url
            if (this.query?.order_no) {
              b = b + '&order_no=' + this.query.order_no
            }
            top.location.href = encodeURI(b)
          }
        }, 4000)
        localStorage.removeItem('query')
      }else if(localStorage.getItem('ZCquery')){
           console.log('执行这里吗？', localStorage.getItem('ZCquery'))
        localStorage.removeItem('ZCquery')
            // location.href = 'http://laos-test.bawanli.com/pages/directCharging/myOrder/myOrder'
            location.assign("http://Myan.atopup.com/pages/directCharging/myOrder/myOrder");
      } else {
        console.log('执行这里吗',localStorage.getItem('query'))
        let url =
          localStorage.getItem('returnAddress') +
          'myOrder?storeId=' +
          localStorage.getItem('storeID')
        location.href = encodeURI(url)
      }
    }
  },
  mounted () {
    this.domain = window.location.hostname + '/submitAfterapp'
    document.addEventListener('visibilitychange', this.is_Visible)
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.is_Visible)
  },
  methods: {
    is_Visible: function (e) {
      if (this.$route.query.a) {
        this.creadersize()
        let a = JSON.parse(this.$route.query.a)
        console.log('执行这里吗？', e.target.visibilityState)
        if (this.abox === true) {
          if (e.target.visibilityState === 'visible') {
            console.log(a.type === '1')
            if (a.type === '1') {
              // this.$router.go(-2)
              let url = a.returnAddress + 'myOrder?storeId=' + a.storeId
              location.href = encodeURI(url)
            }
          }
        }
      } else {
        let url =
          localStorage.getItem('returnAddress') + 'myOrder?storeId=' + a.storeId
        location.href = encodeURI(url)
      }
    },
    backorder () {
      this.$router.go(-1)
    },
    // 监听屏幕宽度
    creadersize () {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let a = false
      if (w > 767) {
        a = false
      } else {
        a = true
      }
      this.abox = a
    },
    //  间隔两面查询订单支付状态
    async inquireState () {
      let a = JSON.parse(this.$route.query.a)
      if (a.type === '1') {
        if (this.abox === false) {
          let b = {
            type: '1',
            orderNo: a.orderNo,
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          this.timer = setInterval(async () => {
            let data = await checkPay(b)
            if (data.code === 200) {
              let url = a.returnAddress + 'Successhtml?value=true'
              location.href = encodeURI(url)
            }
          }, 4000)
        } else {
          let b = {
            type: '1',
            orderNo: a.orderNo,
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          this.timer = setInterval(async () => {
            let data = await checkPay(b)
            if (data.code === 200) {
              // console.log(data)
              let url = a.returnAddress + 'Successhtml?value=true'
              console.log(data, url)
              location.href = encodeURI(url)
            }
          }, 4000)
        }
      } else if (a.type === 2) {
        let b = {
          type: '0',
          orderNo: a.orderNo,
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        this.timer = setInterval(async () => {
          let data = await checkPay(b)
          if (data.code === 200) {
            let url = a.returnAddress + 'Successhtml?value=true'
            location.href = encodeURI(url)
          }
        }, 1000)
      } else if (a.type === '6') {
        let b = {
          type: '4',
          orderNo: a.orderNo,
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        this.timer = setInterval(async () => {
          let data = await checkPay(b)
          if (data.code === 200) {
            let url = a.returnAddress + 'Successhtml?value=true'
            console.log(data, url)
            location.href = encodeURI(url)
          }
        }, 4000)
      }
    },
    getQRcode (data) {
      this.creadersize()
      if (this.abox === false) {
        new Qrcode(this.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: data,
          width: 200,
          height: 200,
          colorDark: '#333', //二维码颜色
          colorLight: '#fff', //二维码背景颜色
          correctLevel: Qrcode.CorrectLevel.L //容错率,L/M/H
        })
      } else {
        new Qrcode(this.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: data,
          width: 300,
          height: 300,
          colorDark: '#333', //二维码颜色
          colorLight: '#fff', //二维码背景颜色
          correctLevel: Qrcode.CorrectLevel.L //容错率,L/M/H
        })
      }
    },
    // 初始化获取订单打开支付
    async creaderdata () {
      let a = JSON.parse(this.$route.query.a)
      localStorage.setItem('returnAddress', a.returnAddress)
      settoken(a.token)
      this.letc = a.type
      this.creadersize()
      console.log('支付接口调用')
      this.inquireState()
      if (this.abox === true) {
        if (a.payMethod === 1) {
          let data1 = {
            orderNo: a.orderNo,
            type: '4',
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: a.storeId
          }
          // 在当前URL后面添加一个简单的参数，以区别于正常情况下的页面路由地址
          // history.pushState(null, '', '?from=wx');
          let { data } = await placeAnOrder(data1)
          console.log(data, '接口调用成功')
          if (data) {
            this.wxdata = data
            location.href = data.h5_url
          }
        } else if (a.payMethod === 4) {
          let data1 = {
            orderNo: a.orderNo,
            type: '6',
            payeeSettingId: a.payeeSettingId,
            userId: a.userId,
            storeId: localStorage.getItem('storeID')
          }
          let { data } = await placeAnOrder(data1)
          location.href = data.qr_code
          console.log(data)
        }
      } else {
        let data1 = {
          orderNo: a.orderNo,
          type: '2',
          payeeSettingId: a.payeeSettingId,
          userId: a.userId,
          storeId: a.storeId
        }
        let { data } = await placeAnOrder(data1)
        this.getQRcode(data.code_url)
        this.wxdata = data
      }
    }
  }
  // beforeDestroy() {
  // 	clearInterval(this.timer);
  // },
}
</script>

<style>
.myHome {
  width: 100%;
  min-height: 910px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.zf {
  width: 800px;
  height: 800px;
  border: 1px solid #c7c6c6;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.back {
  cursor: pointer;
  position: absolute;
  width: 65px;
  height: 30px;
  border: 1px solid #6e4faa;
  border-radius: 15px;
  line-height: 30px;
  font-family: 'Microsoft YaHei';
  text-align: center;
  color: #6e4faa;
  font-weight: 600;
  left: 10px;
  top: 10px;
}

.wphtitle {
  margin-top: 30px;
}

.Yz {
  margin-top: 20px;
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: normal;
  color: #ff4545;
}

.jydh {
  margin-top: 20px;
  color: rgb(189, 189, 189);
}
</style>
