import request from '@/utils/request'
import appRequest from '@/utils/appRequest'

import ZCRequest from '@/utils/zhichongRequest'

// 用户下单
export function placeAnOrder(params) {
  return request({
    url: '/payCon/userOrderPay/placeAnOrder',
    method: 'post',
    params
  })
}

// 前端 支付状态查询
export function checkPay(params) {
  return request({
    url: '/payCon/userOrderPay/checkPay',
    method: 'post',
    noLoading:true,
    params
  })
}
// APP 支付状态
export function appcheckPay(data) {
  return appRequest({
    url: 'v342/Topup/selectKbz',
    method: 'post',
    noLoading:true,
    data
  })
}

// 直充支付状态查询

export function ZCcheckPay(data) {
  return ZCRequest({
    url: 'v342/Topup/selectKbz',
    method: 'post',
    noLoading:true,
    data
  })
}