
import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/Home/index.vue'
import success from '../views/Home/successhtml.vue'
Vue.use(VueRouter);
 
export const constantRoutes=[
{
  path:"/",
  component:Home,
  meta:{title:"首页"}
  },
  {
    path:"/successhtml",
    component:success,
    meta:{title:"成功页面"}
  }
]


 // 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
 const router = new VueRouter({
        routes:constantRoutes,
        mode: 'history'
    })
    router.beforeEach((to, from, next) => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      
     
      if (from.path === '/login') {
        needLogin.forEach((item) => {
          if (item===to.path) {
            next('/')
          } else {
            next()
          }
        })
      } else {
        next()
      }
    })
export default router
